import Vue from 'vue'
import App from './App.vue'
import router from './router';
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false
Vue.use(VueRouter, VueSweetalert2)

new Vue({
  router,
  vuetify,

  render: h => h(App),
}).$mount('#app')
