<template>
   <v-app id="inspire">
      <v-content>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>Login form</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <!-- <v-icon> mdi-checkbox-marked-circle </v-icon> -->
                        <v-form
                        @keyup.enter="handleLogin">
                           <v-text-field
                           name="Username"
                           v-model="username"
                           :rules="nikRules"
                           prepend-icon="mdi-account"
                           counter="9"
                           maxlength="9"
                           type="text"
                  ></v-text-field>
                           <v-text-field
                           id="password"
                           name="PASSWORD"
                           v-model="password"
                           autocomplete="off"
                           prepend-icon="mdi-lock"
                           :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                           :rules="passwordRules"
                           :type="show1 ? 'text' : 'password'"
                           @click:append="show1 = !show1"
                  ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleLogin">Login</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-content>
   </v-app>
</template>

<script>
export default {
   // name: 'Login',
   // props: {
   //    source: String,
   // },

   data(){
      return{
         username:"",
         password:"",
         show1: false,

         nikRules: [
         (v) => !!v || "Username Tidak Boleh Kosong",
        // (v) => (v && v.length >= 10) | "Nik Harus 10 Karakter",
        // (v) => (v && v.length < 11) | "Nik Harus 10 Karakter",
      ],
      passwordRules: [(v) => !!v || "Password Tidak Boleh Kosong"],
      }
   },

   methods: {
       handleLogin(){
      if (this.username == "admMoodle" && this.password == "Adminmoodle@177"){
         this.$router.push({ name: "Setting" });
      }else{
         alert("Gagal Login")
      }
   },
   },

};
</script>

<style></style>
