import VueRouter from 'vue-router';
import Vue from 'vue';
import Login from '../src/components/views/Login.vue'
import Setting from '../src/components/views/Setting.vue'


const routes = [{

        path: "/",
        name: "Login",
        component: Login,
    },
    {

        path: "/Setting",
        name: "Setting",
        component: Setting,
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
});

Vue.use(VueRouter);

export default router;